import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmPaymentIntentMutationVariables = Types.Exact<{
  confirmPaymentIntentInput: Types.ConfirmPaymentIntentInput;
}>;


export type ConfirmPaymentIntentMutation = { __typename?: 'Mutation', confirmPaymentIntent: { __typename?: 'CommandObjectbdwAdTCq', object?: { __typename?: 'CommandObject', id: string } | null, error?: { __typename?: 'CommandObjectbdwAdTCqErrorObject', code?: string | null, message: string } | null } };


export const ConfirmPaymentIntentDocument = gql`
    mutation confirmPaymentIntent($confirmPaymentIntentInput: ConfirmPaymentIntentInput!) {
  confirmPaymentIntent(confirmPaymentIntentInput: $confirmPaymentIntentInput) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type ConfirmPaymentIntentMutationFn = Apollo.MutationFunction<ConfirmPaymentIntentMutation, ConfirmPaymentIntentMutationVariables>;

/**
 * __useConfirmPaymentIntentMutation__
 *
 * To run a mutation, you first call `useConfirmPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPaymentIntentMutation, { data, loading, error }] = useConfirmPaymentIntentMutation({
 *   variables: {
 *      confirmPaymentIntentInput: // value for 'confirmPaymentIntentInput'
 *   },
 * });
 */
export function useConfirmPaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPaymentIntentMutation, ConfirmPaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPaymentIntentMutation, ConfirmPaymentIntentMutationVariables>(ConfirmPaymentIntentDocument, options);
      }
export type ConfirmPaymentIntentMutationHookResult = ReturnType<typeof useConfirmPaymentIntentMutation>;
export type ConfirmPaymentIntentMutationResult = Apollo.MutationResult<ConfirmPaymentIntentMutation>;
export type ConfirmPaymentIntentMutationOptions = Apollo.BaseMutationOptions<ConfirmPaymentIntentMutation, ConfirmPaymentIntentMutationVariables>;